import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Breaking from "../components/breaking"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import HeaderMobile from "../components/Header/headerMobile"
import Header from "../components/Header/header"
import Footer from "../components/Footer/footer"
import { ThemeToggler } from "gatsby-plugin-dark-mode"
import { Disqus, CommentCount } from "gatsby-plugin-disqus"
import CookieConsent, { Cookies } from "react-cookie-consent"

export default class Post extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    let postData = this.props
    let disqusConfig = {
      url: `${"https://www.amazingtec.com.br/" + postData.data.posts.slug}`,
      identifier: postData.data.posts.id,
      title: postData.data.posts.title,
      language: "pt_BR"
    }
    return (
      <>
        <Helmet>
          <html lang="pt-BR" amp />
          <meta charSet="UTF-8" />
          <title>{postData.data.posts.postTitle} |
            {postData.data.posts.categoryList[1] === undefined ?
              postData.data.posts.categoryList[0].category :
              postData.data.posts.categoryList[1].category } | AmazingTec</title>
          <meta name="description" content={postData.data.posts.metaDescription.metaDescription} />
          <meta name="robots" content="noindex,nofollow,max-snippet: -1,max-image-preview:large" />
          <link rel="canonical" href={"https://dev.amazingtec.com.br/"+postData.data.posts.slug+"/"} />
          <meta property="og:locale" content="pt-BR" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content={postData.data.posts.postTitle + " | " +
          (postData.data.posts.categoryList[1] === undefined ?
              postData.data.posts.categoryList[0].category :
              postData.data.posts.categoryList[1].category) + " | AmazingTec"} />
          <meta property="og:description" content={postData.data.posts.metaDescription.metaDescription} />
          <meta property="og:url" content={"https://dev.amazingtec.com.br/"+postData.data.posts.slug+"/"} />
          <meta property="og:sitename" content="AmazingTec" />
          <meta property="article:publisher" content="https://facebook.com/amazingtec" />
          <meta property="article:published_time" content={postData.data.metaData.publishDate} />
          <meta property="article:modified_time" content={postData.data.metaData.updatedAt} />
          <meta property="og:image" content={postData.data.posts.realFeaturedImage.localFile.url} />
        </Helmet>
        <Header/>
        <HeaderMobile/>
        <div className="post">
          <div className={"col-12 columns"}>
            <div className="post-item">
              <div className="overlay"></div>
              <Img className="image" fluid={postData.data.posts.realFeaturedImage.localFile.childImageSharp.fluid}/>
              <div className="title">{postData.data.posts.postTitle}</div>
              <div className="details">
                <div className="date">
                  <i className="la la-clock-o"></i> <b>{postData.data.posts.publishDate}</b>
                </div>
                <div className="author">
                  <i className="la la-user"></i> <Link className="author-link"
                                                       to={postData.data.posts.postAuthor[0].authorSlug}><b>{postData.data.posts.postAuthor[0].authorName}</b></Link>
                </div>
                <div className="comments">
                  <i className="la la-comment"></i> <b><CommentCount config={disqusConfig} language="pt_BR" placeholder={"comentário(s)"}/></b>
                </div>
                <div className="layer">
                  <i className="la la-hashtag"></i> {
                  postData.data.posts.categoryList.map((s, i) => {
                    return (
                      <>
                        <Link
                          to={s["categorySlug"]}>{s["category"]}</Link><b>{i === (postData.data.posts.categoryList.length - 1) ? "" : ", "}</b>
                      </>
                    )
                  })
                }
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="post-txt"
                   dangerouslySetInnerHTML={{ __html: postData.data.posts.postBody.childMarkdownRemark.html }}/>
            </div>
          </div>
          <div className="container">
            <Disqus config={disqusConfig}/>
          </div>
        </div>
        <Footer/>
        <CookieConsent
          location="bottom"
          buttonText="Permitir"
          declineButtonText="Negar"
          flipButtons
          enableDeclineButton
          onAccept={() => {
            console.log("user's with us, boys");
            Cookies.set("gatsby-gdpr-google-analytics","true");
            Cookies.set("gatsby-gdpr-google-tagmanager","true");
            Cookies.set("gatsby-gdpr-facebook-pixel","true");
            Cookies.set("gatsby-gdpr-google-ads","true");
          }}
          onDecline={() => {
            console.log("user's not with us, boys");
            Cookies.set("gatsby-gdpr-google-analytics","false");
            Cookies.set("gatsby-gdpr-google-tagmanager","false");
            Cookies.set("gatsby-gdpr-facebook-pixel","false");
            Cookies.set("gatsby-gdpr-google-ads","false");
          }}>
          Este site usa cookies de terceiros para enriquecer sua experiência. Para conferir nossa Política de
          Privacidade, <Link to="/politica-de-privacidade">clique aqui</Link>.
        </CookieConsent>
      </>
    )
  }
}
export const query = graphql`
    query($slug:String!) {
        metaData:contentfulPost(slug:{eq:$slug}) {
          publishDate
          updatedAt
        }
        posts:contentfulPost(slug:{eq:$slug}) {
            realFeaturedImage {
                localFile {
                url
                    childImageSharp {
                        fluid(quality: 100, maxWidth: 1200) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
            postBody {
                childMarkdownRemark {
                    html
                }
            }
            categoryList {
                id
                categorySlug
                category
            }
            postTitle
            slug
            metaDescription {
              metaDescription
            }
            postAuthor {
                authorName
                authorId
                id
                authorSlug
            }
            id
            publishDate(formatString: "DD/MM/YYYY, HH:MM")
            postBody {
                childMarkdownRemark {
                    html
                }
            }
        }
        site {
            siteMetadata {
                facebookAPI
            }
        }
    }
`
