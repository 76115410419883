import React, { Component } from 'react'

class Breaking extends Component {
  render() {
    return (
      <div className="container breaking-container">
        <span className="amztc-badge">Últimas</span>
        <span className="">Samsung anuncia novo Galaxy Tab S4, tablet topo de linha da empresa</span>
      </div>
    )
  }
}
export default Breaking